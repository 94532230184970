/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.block-ui-wrapper.block-ui-main.active {
    background: rgba(0, 0, 0, 0.5) !important;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

.adminBody {
    display: flex;
    flex-direction: column;
    margin: 0!important;
    padding: 0!important;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.footerBlock {
    margin-top: auto;
}

.adminBody .mdc-card {
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.listingpage_wrapper {
    display: block;
    padding: 0 40px!important;
    margin: 0!important
}

.listingpage_wrapper .listingpageh2 {
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
    padding: 15px;
    display: block;
    background: rgb(1, 187, 221);
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    clip-path: polygon(0 0, 100% 0, 94% 100%, 6% 100%);
    font-size: 26px;
    color: #fff;
    margin-bottom: 25px;
    text-align: center
}

.adminlisttable_v2 .mat-mdc-checkbox-touch-target {
    height: 23px;
    width: 23px;
}

.url_head_txt {
    border-radius: 5px;
    margin: 0 auto;
    padding: 15px;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    font-size: 23px;
    font-weight: 600;
    max-width: 800px;
    line-height: 30px;
    color: #fff;
    text-align: center;
}

.adminlisttable_v2 .mdc-checkbox__native-control {
    height: 100% !important;
    width: 100% !important;
}

.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #fc9826;
    background-color: #fc9826;
}

.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #fc9826;
    background-color: #fc9826;
}

.admin-list_v2 {
    position: relative
}

.adminlisttable_v2 .container {
    clear: both;
    overflow: hidden
}

.adminlisttable_v2 .mdc-card {
    padding: 0!important;
    box-shadow: none!important;
    clear: both;
    position: relative;
    display: block!important;
    z-index: 11;
}

.adminlisttable_v2 .tablewrapper {
    float: left;
    width: 70%;
    padding-top: 54px
}

.adminlisttable_v2 .noFoundText {
    float: left;
    width: 70%;
    position: absolute;
    padding-top: 62px!important
}

.adminlisttable_v2 .togglesearchcls {
    float: right;
    width: 28%;
    padding: 0;
    border: none;
    position: relative;
    padding-top: 80px;
    background: #f1f1f1;
    border-radius: 10px
}

.adminlisttable_v2 .togglesearchcls .search_class {
    display: flex;
    width: 98%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto
}

.adminlisttable_v2 .togglesearchcls .CustomButtonListen_div {
    position: absolute;
    padding: 0;
    top: 10px;
    left: 2%;
    width: 96%
}

.adminlisttable_v2 .togglesearchcls .CustomButtonListen_div .add_button {
    width: 100%;
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 22px;
    line-height: 50px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    box-shadow: none
}

.togglesearchcls .searchbtncls .material-icons {
    width: 45px;
    border-radius: 50%;
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: 45px;
    margin: auto;
    text-align: center;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0 20px 0 0;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center
}

.adminlisttable_v2 .togglesearchcls .searchbtncls .material-icons {
    width: 45px!important;
    height: 45px!important;
    line-height: 45px;
    font-size: 30px
}

.adminlisttable_v2 .searchbtncls .search_class button {
    font-size: 0!important;
    border-radius: 100px;
    width: 45px!important;
    height: 45px;
    padding: 0;
    min-width: inherit;
    background-size: 60%!important;
    margin: 0 5px;
    position: relative;
    top: 0;
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    cursor: pointer;
    box-shadow: none
}

.adminlisttable_v2 .searchbtncls .search_class button span {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/searchicon4.png') no-repeat center center!important;
    display: block;
    background-size: 60%!important
}

.adminlisttable_v2 table,
.adminlisttable_v2 th,
.adminlisttable_v2 td {
    display: block;
    border: none;
    min-width: inherit!important;
    box-shadow: none!important;
}

.adminlisttable_v2 thead {
    display: block;
    padding: 0;
    background-color: #02619b!important;
}

.adminlisttable_v2 tr {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap
}

.adminlisttable_v2 tr th {
    flex: 1 0 25%;
    text-align: left;
    display: flex;
    align-items: center;
    border: solid 1px #01c2e3!important;
    padding: 0.5%;
    color: #fff;
    font-weight: 600;
}

.selectAllCheckbox {
    color: #fff;
    font-weight: 600;
}

.adminlisttable_v2 tr th button {
    padding: 0;
    color: #fff;
}

.adminlisttable_v2 tr th span {
    display: flex;
    align-items: center;
}

.adminlisttable_v2 tbody {
    border: none;
    display: block;
    min-width: inherit!important;
    padding: 0
}

.adminlisttable_v2 tbody tr {
    border: 1px solid #ccc;
    margin: 10px 0
}

.adminlisttable_v2 tbody tr td {
    border: 1px solid #ccc!important;
    flex: 1 0 25%;
    font-weight: 400;
    padding: .5%!important;
    display: flex;
    overflow: inherit;
    align-items: center
}

.adminlisttable_v2 tbody tr td span {
    word-break: break-all;
}

.adminlisttable_v2 .mat-mdc-table .mdc-data-table__row {
    height: auto
}

.adminlisttable_v2 .mat-mdc-table .mdc-data-table__header-row {
    height: auto
}

.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox {
    padding: 0;
    margin: 0
}

.adminlisttable_v2 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0
}

.adminlisttable_v2 tbody .edit_btn button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/editlist.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.adminlisttable_v2 tbody .OpenFolderView button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/folder_view.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 22px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.adminlisttable_v2 tbody .delete_btn button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/deletIcon.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.adminlisttable_v2 .lib-pager-class {
    display: none!important
}

.admin-list_v1 tbody .map_symptoms button,
.admin-list_v1 tbody .map_cpt_code button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/map_symptoms.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.admin-list_v1 tbody .map_icd_code button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/icd_icon.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 26px!important;
    height: 30px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.admin-list_v1 tbody .related_diagnosis button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/diagnosticIcon1.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.admin-list_v1 tbody .assay_range button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/range_icon.webp') no-repeat center center!important;
    background-size: 100%!important;
    width: 20px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}


.adminlisttable_v2 tbody .download_patient_details_button button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/download_clinical_report_icon.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 25px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.admin-list_v1 tbody .approve_diagnosis button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/check-circle.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 22px!important;
    height: 22px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.labReportList tbody .labreport button {
    /* background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/folder_view.png') no-repeat center center!important;
    background-size: 100%!important; */
    background: none;
    position: relative;
    width: 22px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.labReportList tbody .labreport button::after {
    position: absolute;
    content: "\f15b";
    font: normal normal normal 14px/1 FontAwesome;
    /* font-size: inherit; */
    font-size: 24px;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    color: #16425e;
}

.form_field_wrapperpassword .GeneratePasswordcls,
.form_field_wrapperconfirmpassword .GeneratePasswordcls {
    display: none;
}

.form_field_wrapperpassword,
.form_field_wrapperconfirmpassword {
    position: relative;
}

.form_field_wrapperpassword .passbuttoncls,
.form_field_wrapperconfirmpassword .passbuttoncls {
    position: absolute;
    right: 8px;
    top: 18px
}

.addEditPageWrapper.addEditDoctor .form_field_wrapperpassword,
.addEditPageWrapper.addEditDoctor .form_field_wrapperconfirmpassword {
    flex: 1 0 49%;
}

.taxonomies_formstyle .form_field_wrapperdescription,
.taxonomies_formstyle .form_field_wrappertraining_category_des {
    flex: 1 0 99% !important;
}

.taxonomies_formstyle .form_field_wrappericd_description {
    flex: 1 0 99% !important;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_wrappericd_description .mat-mdc-form-field-flex {
    height: 180px!important;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_description .mat-mdc-form-field-flex,
.addEditPageWrapper .taxonomies_formstyle .form_field_wrappertraining_category_des .mat-mdc-form-field-flex {
    height: 180px!important
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_description .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea,
.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_wrappertraining_category_des .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea {
    height: 160px;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_diagnostic_des .mat-mdc-form-field-flex {
    height: 180px!important
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_diagnostic_des .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea {
    height: 160px;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_wrapperassay_des .mat-mdc-form-field-flex {
    height: 180px!important
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_wrapperassay_des .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea {
    height: 160px;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_wrapperassay_des {
    flex: 1 0 98%;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_wrappersymptoms_des .mat-mdc-form-field-flex {
    height: 180px!important
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_wrappersymptoms_des .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea {
    height: 160px;
}

.addEditPageWrapper .taxonomies_formstyle .form_field_wrappersymptoms_des {
    flex: 1 0 98%;
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_assay_des .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea {
    height: 160px;
}

.addEditPageWrapper .taxonomies_formstyle .mat-mdc-form-field.form_field_description .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea.ng-pristine.ng-invalid {
    /* margin-left: -60px; */
}


/* mat-mdc-input-element ng-tns-c115-4 mat-mdc-form-field-textarea-control mat-mdc-form-field-input-control mdc-text-field__input ng-untouched cdk-text-field-autofill-monitored ng-pristine ng-invalid */

.taxonomies_liststyle .tablewrapper tbody tr td {
    max-width: 100%
}

.adminlisttable_v2 .lib-pager-class:nth-of-type(1) {
    display: inline-block!important
}

.adminlisttable_v2 .lib-pager-class .pageformfield {
    display: none!important;
}

.listingtableheading {
    width: 70%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8
}

.addeditpage_wrapper .AddEditBlog {
    background: #f1f1f1;
    border-radius: 10px;
    width: 85%;
    margin: 0 auto
}

.addeditform .container {
    padding: 21px
}

.AddEditBlog .addeditform {
    overflow: hidden;
    position: relative;
    padding: 0!important
}

.actionbuttonwrapper .actionbuttonwrapperspan {
    display: flex;
    align-items: center;
}

.actionbuttonwrapper .actionbuttonwrapperspan .cursor {
    display: inline-flex;
    align-items: center;
    margin: 0 2px;
    color: #0c3956;
}

.listingtableheading {
    width: 70%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8
}

.listingtableheading_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: solid 1px #01c2e3;
    padding: 10px 1%;
    background: #02609a;
    margin: 0;     align-items: center;
}

.listingtableheading_wrapper h2 {
    margin: 0;
    padding: 0;
    display: flex;
    color: #f4f1f1;
    align-items: center
}

.listingtableheading_wrapper h2 label {
    margin: 0;
    padding: 0 15px 0 0;
    color: #f4f1f1;
    font-size: 20px;
    font-weight: 400;
    position: relative
}

.adminlisttable_v2 .lib-pager-class:first-of-type {
    display: flex!important;
    align-items: center;
    justify-content: right;
    width: 250px;
    position: absolute;
    right: 30%;
    z-index: 9;
    text-align: right;
    color: #fff;
    top: 16px;
    padding-right: 15px
}

.noFoundTextinner {
    text-align: center!important;
    font-weight: 700!important;
    font-size: 16px!important;
    color: red!important;
    margin-top: 20px;
    position: relative;
}


/* togglesearchcls */

.adminlisttable_v2 .togglesearchcls {
    padding-left: 16px;
    padding-right: 16px;
}

.adminlisttable_v2 .togglesearchcls br {
    display: none;
}

.adminlisttable_v2 .togglesearchcls .mat-mdc-form-field {
    width: 100%;
    margin: 0!important;
    display: block
}

.adminlisttable_v2 .togglesearchcls .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: .5px solid #bdbdbd;
    padding: 0 16px 3px;
    background: #fff;
    border-radius: 10px;
    height: 54px;
    box-sizing: inherit!important
}

.userListTable.adminlisttable_v2 .togglesearchcls .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    margin-bottom: 20px;
}

.userListTable.adminlisttable_v2 .togglesearchcls .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.adminlisttable_v2 .mdc-notched-outline div {
    border: none!important;
}

.adminlisttable_v2 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    display: none!important
}


/* togglesearchcls end */

.adminlisttable_v2 .example-section br {
    display: none;
}

.adminlisttable_v2 .example-section {
    position: relative;
    width: 70%;
}

.adminlisttable_v2 .example-section .mat-mdc-progress-bar {
    z-index: 9;
}

.adminlisttable_v2 .example-section {
    position: absolute;
    left: 0;
    top: 0
}

.adminlisttable_v2 th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #fff;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: #1a2e46
}

.mdc-linear-progress__buffer-dots {
    border-color: #fe9900
}

.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #fe9900
}

.adminlisttable_v2 .multipledeleteandupdatebuttan {
    position: absolute;
    z-index: 9;
    text-align: right;
    display: block;
    width: 70%;
    padding: 8px 270px 0 0;
}

.adminlisttable_v2 .multipledeleteandupdatebuttan button {
    margin: 0 2px;
    background: #fe9900;
    color: #fff;
}

.mat-bottom-sheet-container {
    border: solid 10px #1a2e46;
    padding: 16px 20px!important;
    margin: 0 auto;
}

.mat-bottom-sheet-container .bottomSheetWrap {
    border-radius: 0!important;
    box-shadow: none!important;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: inherit
}

.mat-bottom-sheet-container .bottomSheetWrap button {
    flex: 1 0 auto;
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 18px;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 15px;
    color: #fff;
    cursor: pointer;
    box-shadow: none;
    margin: 2px;
}

.actionbuttonwrapper {
    display: flex;
}

.button_div_custom_cls {
    display: flex;
    align-items: center
}

.accountSubMenu .mdc-list {
    padding: 5px!important;
}

.accountSubMenu .mdc-list h1 {
    margin: 0;
    padding: 5px 10px;
    border-radius: 4px;
    background: #fe9900;
    color: #fff;
    font-size: 22px;
}

.accountSubMenu .mdc-list button {
    display: block;
    margin: 0;
    padding: 10px 5px;
    border-bottom: solid 1px #ccc;
    font-size: 16px;
    min-height: inherit;
    display: flex;
}

.accountSubMenu .mdc-list button:last-of-type {
    border: none;
    display: flex;
}

.laboratoryForm .filecontainerdiv .logoImageDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100px;
}

.logoImage {
    display: flex;
    width: auto;
    object-fit: cover;
    height: 100%;
}

.headerBody .mat-mdc-button-touch-target {
    height: auto!important;
}

.headerBody .mat-mdc-unelevated-button>.mat-icon {
    margin: 0!important;
    padding: 0!important;
}

.headerMenu .active {
    background-color: #fe9900!important;
    color: #fff!important;
}

.categoryListCard .categoryList.active {
    background: rgb(1, 187, 221) !important;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%) !important;
    color: #fff;
}

.categoryListCard .categoryList.active .categoryArrow {
    background: #fff !important;
    color: #000 !important;
}

.active .mdc-list-item__primary-text {
    color: #fff!important
}

.messageShow_flag {
    display: block;
    text-align: center;
}

.custom-bottomsheet .bottom-sheet-header-toggle {
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
}

.custom-bottomsheet mat-nav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-bottomsheet mat-nav-list .mat-mdc-list-item {
    width: 100px;
    margin: 5px;
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 22px;
    line-height: 50px;
    border-radius: 5px;
    color: #fff!important;
    cursor: pointer;
    box-shadow: none;
    padding: 0 16px!important;
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-weight: bold!important;
}

.custom-bottomsheet mat-nav-list .mdc-list-item__primary-text {
    color: #fff!important;
    font-weight: bold!important
}

.cdk-global-scrollblock {
    overflow-y: hidden;
    top: 0!important
}

.mat-mdc-dialog-surface .close-btn-modal {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px;
    color: #fff;
    border-radius: 0 0 0 10px;
    background: #1a2e46;
    padding: 0 0 3px 2px;
    font-size: 20px;
    z-index: 9;
}

.mat-mdc-dialog-surface .close_button {
    position: absolute;
    color: #fff;
    border-radius: 0 0 0 10px;
    background: #1a2e46;
    padding: 0 0 3px 2px;
    z-index: 9;
    right: -20px;
    top: -20px;
    border-width: inherit;
}

.mat-mdc-dialog-surface .close_button .mat-icon {
    height: 22px;
    width: 22px;
    font-size: 0;
}

.mat-mdc-dialog-surface .close_button .mat-icon::after {
    content: "\00d7";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    font-size: 27px
}

.mat-mdc-dialog-surface .listingsearchtitle {
    margin: 0 20px 20px 20px;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    color: #fff!important;
    font-size: 22px!important;
}

.mat-mdc-dialog-surface .mat-mdc-dialog-title {
    margin: 0;
    padding: 0;
    text-align: center;
}

.mat-mdc-dialog-surface .mat-mdc-dialog-content {
    margin: 10px 0 0 0;
    padding: 0!important;
    text-align: center;
}

.mat-mdc-dialog-surface .mat-mdc-dialog-title::before {
    display: none;
}

.mat-mdc-dialog-actions {
    justify-content: center!important;
}

.detail-view .mat-mdc-dialog-surface {
    width: 630px!important;
    max-width: 90%!important;
    padding: 0!important;
    margin: 0
}

.detail-view .mat-mdc-dialog-surface .dialoghead .mat-mdc-dialog-content {
    padding: 20px!important;
    margin: 0!important;
}

.detail-view .mat-mdc-dialog-title {
    margin: 20px 20px 0 20px;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    color: #fff!important;
    font-size: 22px!important;
}

.detail-view .example-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px!important;
    box-shadow: none!important;
    flex-direction: inherit!important
}

.detail-view .example-card:nth-of-type(odd) {
    background: #d6dadc
}

.detail-view .example-card .mat-mdc-card-header {
    width: 30%;
    margin: 0;
    padding: 0!important;
    display: flex;
    align-self: center;
}

.detail-view .example-card .mat-mdc-card-content,
.mat-mdc-card-subtitle {
    width: 60%;
    margin: 0;
    padding: 0!important;
    text-align: left;
    display: flex;
    align-self: center;
    color: #111
}

.detail-view .example-card .mat-mdc-card-title {
    font-size: 16px;
    color: #1a2e46;
    text-align: left;
    line-height: 20px;
}

.api-data .mat-mdc-dialog-surface {
    width: 630px!important;
    max-width: 90%!important;
    /* padding: 0!important; */
    margin: 0
}

.api-data .mat-mdc-dialog-surface .mat-mdc-dialog-content p.innerhtml-content {
    margin-top: 0px !important;
    word-break: break-word;
}

.api-data .mat-mdc-dialog-surface .mat-mdc-dialog-content p.innerhtml-content img {
    width: auto;
    max-width: 200px;
}

.api-data .mat-mdc-dialog-surface .dialoghead .mat-mdc-dialog-content {
    padding: 20px!important;
    margin: 0!important;
}

.api-data .mat-mdc-dialog-title {
    margin: 20px 20px 0 20px;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    color: #fff!important;
    font-size: 22px!important;
}

.api-data .example-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px!important;
    box-shadow: none!important;
    flex-direction: inherit!important
}

.api-data .example-card:nth-of-type(odd) {
    background: #d6dadc
}

.api-data .example-card .mat-mdc-card-header {
    width: 38%;
    margin: 0;
    padding: 0!important;
    display: flex;
    align-self: center;
}

.api-data .example-card .mat-mdc-card-content,
.mat-mdc-card-subtitle {
    width: 60%;
    margin: 0;
    padding: 0!important;
    text-align: left;
    display: flex;
    align-self: center;
    color: #111
}

.api-data .example-card .mat-mdc-card-title {
    font-size: 16px;
    color: #1a2e46;
    text-align: left;
    line-height: 20px;
}

.api-data .example-card .mat-mdc-card-header .mat-mdc-card-title {
    text-transform: capitalize;
}

.noFoundTextinnerBG {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid #ddd;
    margin: 0;
    padding: 15px;
    height: 100%;
}

.libListNoDataCSS .listingtableheading {
    position: inherit;
    width: 100%;
}

.libListNoDataCSS .adduserBTN {
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 22px;
    line-height: 40px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    box-shadow: none;
    border: solid 1px #7b8890;
    padding: 0 20px;
}

.libListNoDataCSS .togglesearchcls {
    display: none;
}


/* =========================== List Style1 Css Start ==============================*/

.rapidResponseVirtualAccessModal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
    top: 0!important
}

.rapidResponseVirtualAccessModal .list_style1 {
    padding-top: 16px;
    overflow-y: auto;
    max-height: 80vh;
}

.rapidResponseVirtualAccessModal .approveButton button,
.rapidResponseVirtualAccessModal .approveButton button:hover {
    padding: 0px 16px !important;
}

.rapidResponseVirtualAccessModal .allAcceptedMsg {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-top: 16px;
}

.admin-list_v1 .listingtableheading {
    width: 100%;
    position: inherit
}

.admin-list_v1 .togglesearchcls .mat-toolbar-row {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    background: #91a1a1;
    position: relative;
    align-items: center;
}

.adminBody .admin-list_v1 .mdc-card {
    position: relative
}

.admin-list_v1 .example-section .mat-mdc-progress-bar {
    position: absolute;
    left: 0;
    top: 0
}

.admin-list_v1 .togglesearchcls {
    position: relative;
    padding-bottom: 80px;
    overflow: hidden
}

.admin-list_v1 .mat-toolbar-row .mat-mdc-form-field {
    flex: 1 0 32.33%;
    margin: 0;
    margin: .5%
}

.admin-list_v1 .mat-toolbar-row .mat-mdc-form-field .mdc-notched-outline__leading,
.admin-list_v1 .mat-toolbar-row .mat-mdc-form-field .mdc-notched-outline__notch,
.admin-list_v1 .mat-toolbar-row .mat-mdc-form-field .mdc-notched-outline__trailing {
    border-radius: 0;
    border: 0
}

.admin-list_v1 .mat-toolbar-row .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    border-radius: 4px;
}

.DiagnosticCategory .mat-toolbar-row .mat-mdc-form-field {
    flex: 1 0 23.33%;
    margin: 0;
    margin: .5%
}

.admin-list_v1 .searchbtncls {
    width: 100%
}

.admin-list_v1 .searchbtncls .search_class {
    display: flex;
    justify-content: center;
    align-items: center
}

.adminlisttable_v1 .searchbtncls .search_class button span {
    background: url(https://all-frontend-assets.s3.amazonaws.com/practice2lab/searchicon4.png) no-repeat center center!important;
    display: block;
    background-size: 60%!important
}

.adminlisttable_v1 .searchbar .mat-mdc-form-field-icon-prefix {
    padding: 0 6px 0 12px
}

.adminlisttable_v1 .togglesearchcls .searchbar .filterForAuto .mat-mdc-form-field-infix .mat-mdc-chip {
    margin: 0px 3px 10px;
}

.adminlisttable_v1 .searchbtncls .search_class button {
    font-size: 0!important;
    border-radius: 100px;
    width: 45px!important;
    height: 45px;
    padding: 0;
    min-width: inherit;
    background-size: 60%!important;
    margin: 0 5px;
    position: relative;
    top: 0;
    background: #284261;
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    cursor: pointer;
    box-shadow: none
}

.admin-list_v1 .CustomButtonListen_div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0!important;
    position: absolute;
    bottom: -72px;
    padding-left: 460px!important
}

.adminlisttable_v1 .togglesearchcls .CustomButtonListen_div .add_button {
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 18px;
    line-height: 50px
}

.adminlisttable_v1 tbody .delete_btn button {
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/deletIcon.png') no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.libListNoDataCSS .adduserBTN {
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 22px;
    line-height: 40px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    box-shadow: none
}


/* .admin-list_v1 .lib-pager-class .mat-mdc-form-field {
    margin: 0 4px;
    width: 153px
} */

.admin-list_v1 .lib-pager-class .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.admin-list_v1 .lib-pager-class .mat-mdc-text-field-wrapper {
    background: linear-gradient(180deg, rgba(40, 188, 231, 1) 0%, rgba(26, 141, 175, 1) 100%);
    border: 1px solid #1a88a9;
    border-radius: 0;
    padding: 7px 7px;
    margin: 0
}

.admin-list_v1 .lib-pager-class .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: inherit;
    justify-content: center;
}

.rapidResponseVirtualAccessModal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-form-field .mat-mdc-form-field-flex {
    height: auto!important;
    padding: 4px 10px;
}

.rapidResponseVirtualAccessModal .mat-mdc-dialog-surface .closealert {
    top: -30px;
    right: -20px;
}

.admin-list_v1 .lib-pager-class .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mdc-floating-label {
    position: inherit;
    left: inherit;
    top: inherit;
    transform: inherit;
    font-size: 14px;
    color: #fff
}

.admin-list_v1 .lib-pager-class .mat-mdc-text-field-wrapper .mdc-line-ripple {
    display: none;
}

.admin-list_v1 .lib-pager-class .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix input {
    width: 86px;
    background: #d9f2f9;
    text-align: center;
    border: 1px solid #1b84a3
}

.admin-list_v1 .lib-pager-class {
    display: flex!important;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    margin-top: -80px;
    background: rgb(213, 221, 221);
    background: linear-gradient(90deg, rgba(213, 221, 221, 1) 0%, rgba(213, 221, 221, 0) 100%)
}

.admin-list_v1 .lib-pager-class mat-label {
    position: relative;
    z-index: 1;
    margin-right: 10px;
    /* min-width: 170px; */
}

.admin-list_v1 .container .lib-pager-class:last-child {
    display: none !important;
}

.admin-list_v1 .lib-pager-class label,
.admin-list_v1 .lib-pager-class span {
    z-index: 1;
}

.admin-list_v1 .lib-pager-class span .material-icons {
    height: 40px;
    width: 40px;
    background: #a7b7b7;
    text-align: center;
    color: #fff;
    line-height: 39px;
    margin: 0 2px;
    border: 1px solid #1080a1;
    text-shadow: 1px 1px 1px #414c4c;
    background: linear-gradient(180deg, rgba(40, 188, 231, 1) 0%, rgba(26, 141, 175, 1) 100%)
}

.adminBody .admin-list_v1 .mdc-card {
    background: transparent;
    background-color: transparent;
}

.admin-list_v1 .tablewrapper {
    padding: 0;
    background: #c6d5d5;
    position: relative;
    z-index: 9
}

.admin-list_v1 .mat-mdc-table {
    box-shadow: none!important;
    border: 1px solid #9baebb
}

.admin-list_v1 thead tr {
    background: #02619b;
}

.admin-list_v1 thead tr th {
    color: #fff;
}

.admin-list_v1 thead tr th span {
    display: flex;
    align-items: center;
}

.admin-list_v1 th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: white
}

.admin-list_v1 tbody tr {
    height: auto
}

.admin-list_v1 tbody tr:nth-child(even) {
    background-color: #dce9f1
}

.admin-list_v1 tfoot {
    display: none;
}

.admin-list_v1 .listingtableheading_wrapper {
    margin: 0 0px 10px 0px;
    border: solid 1px #ffbf83;
    background: rgb(245, 143, 26);
    background: linear-gradient(180deg, rgba(245, 143, 26, 1) 0%, rgba(191, 109, 15, 1) 100%)
}

.admin-list_v1 .listingtableheading_wrapper h2 label {
    font-size: 24px;
    color: #fff;
    text-shadow: 1px 1px 2px #974c08
}

.ng-touched .mdc-notched-outline__notch {}

.admin-list_v1 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.admin-list_v1 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.admin-list_v1 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #fc9826 !important;
    background-color: #fc9826 !important;
}

.adminlisttable_v1 .multipledeleteandupdatebuttan {
    margin-left: 760px;
    margin-top: -41px;
    top: -22px;
    position: relative;
    text-align: right;
}

.adminlisttable_v1 .multipledeleteandupdatebuttan button {
    border: solid 1px #ffbf83;
    background: rgb(245, 143, 26);
    background: linear-gradient(180deg, rgb(255 169 70) 0%, rgb(223 125 12) 100%);
    color: #fff;
    padding: 16px;
    margin: 0 5px;
    text-shadow: 1px 1px 1px #a1520a;
    margin-bottom: 2px;
}

.adminlisttable_v1 .multipledeleteandupdatebuttan button .mdc-button__label::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit
}

.chooseDiagnosisLibList.adminlisttable_v1 .multipledeleteandupdatebuttan button:nth-child(1) .mdc-button__label::before {
    content: "\f14a"
}

.adminlisttable_v1 .multipledeleteandupdatebuttan button:nth-child(1) .mdc-button__label::before {
    content: "\f014"
}

.adminlisttable_v1 .multipledeleteandupdatebuttan button:nth-child(2) .mdc-button__label::before {
    content: "\f205";
}

.adminlisttable_v1 tbody .edit_btn button {
    background: url(https://all-frontend-assets.s3.amazonaws.com/practice2lab/editlist.png) no-repeat center center!important;
    background-size: 100%!important;
    width: 18px!important;
    height: 25px!important;
    font-size: 0;
    box-shadow: none!important;
    border-radius: 0;
    padding: 0;
    margin: 4px 4px;
    min-width: inherit
}

.list_style1 {
    background: #c6d5d5;
    padding-bottom: 20px!important;
}

.admin-list_v1 .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.admin-list_v1 .container {
    background: transparent!important;
    padding: 0 0px
}

.clinical_report_download_modal {
    width: 320px;
    display: block;
    text-align: center;
}

.clinical_report_download_modal .order_progress {
    padding: 6px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.clinical_report_download_modal .order_progress-bar {
    height: 10px;
    background-color: #ee303c;
    border-radius: 4px;
    transition: 0.4s linear;
    transition-property: width, background-color;
}

.clinical_report_download_modal .order_progress-striped .order_progress-bar {
    background-color: #FCBC51;
    width: 100%;
    background-image: linear-gradient( 45deg, rgb(252, 163, 17) 25%, transparent 25%, transparent 50%, rgb(252, 163, 17) 50%, rgb(252, 163, 17) 75%, transparent 75%, transparent);
    animation: progressAnimationStrike 5s;
}

@keyframes progressAnimationStrike {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

.emailIntakeList .multipledeleteandupdatebuttan button span.mdc-button__label::before {
    content: '' !important;
}


/* <------------------CSS By Sanket------------------> */

.listingpage_wrapper mat-chip-list mat-chip {
    text-overflow: ellipsis;
}

.listingpage_wrapper mat-chip-list mat-chip.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
    overflow: hidden;
}

.listingpage_wrapper .example-section {
    height: 0 !important;
}

.listingpage_wrapper .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper .mdc-floating-label--float-above {
    display: none;
}

.listingpage_wrapper .mat-mdc-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple {
    display: none;
}

.progressbar_wrapper {
    margin: 0 20px;
}

.diagnostic_listwrapper .tablewrapper tbody tr td:nth-child(5) {
    word-break: break-word;
    width: 10%;
}

.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface {
    padding: 0px !important;
}

.labDiagnosisModal .listingpage_wrapper {
    padding: 0px 20px !important;
}

.labDiagnosisModal .listingpage_wrapper .listmaindivbody {
    padding-left: 10px;
    padding-right: 10px;
}

.labDiagnosisModal .listingpage_wrapper .listmaindivbody .mat-mdc-form-field {
    margin: .5% !important;
}

.diagnostic_listwrapper .listingpage_wrapper .progressbar_wrapper,
.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal .labDiagnosis .progressbar_wrapper {
    margin: 0px;
}

.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal .labDiagnosis .mat-mdc-form-field .mat-mdc-form-field-flex {
    height: 50px !important;
}

.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal.adminAvailableTestModal .labDiagnosis .mat-mdc-form-field .mat-mdc-form-field-flex {
    height: auto !important;
}

.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal .adminBody,
.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal .list_style1 {
    min-height: 80vh;
}

.labDiagnosisModal .mdc-dialog__container .mat-mdc-dialog-surface .symptomReasonModal .list_style1 {
    padding-bottom: 20px !important;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field {
    margin: 0 4px !important;
    display: inline-flex !important;
    width: 153px;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    margin: 0px !important;
    padding: 7px !important;
    overflow: hidden !important;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    height: auto !important;
    background: none;
    border: none;
    padding: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label {
    top: inherit !important;
    color: #fff !important;
    background: none !important;
    padding: 0px;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 thead tr th:first-child,
.labDiagnosisModal .labDiagnosis.admin-list_v1 tbody tr td:first-child {
    padding: 5px 12px;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 tbody tr {
    position: relative;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 thead tr .hiddenButton {
    padding: 0 !important;
    border: none;
    background: none;
}

.labDiagnosisModal .labDiagnosis.admin-list_v1 tbody tr .overlayButton {
    cursor: pointer;
    background: none;
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.mat-mdc-dialog-container .selectedRow .chosse_btn {
    /* background: none;
    background-size: 100% !important;
    font-size: 0;
    box-shadow: none !important; */
    /* padding: 0 !important; */
    /* margin: 4px 4px;
    min-width: inherit;
    position: relative;
    border: 1px solid #fc9826;
    width: 16px !important;
    height: 16px !important;
    border-radius: 4px;
    background: #fc9826; */
}

.mat-mdc-dialog-container .chosse_btn {
    /* background: none;
    background-size: 100% !important; */
    /* width: 18px !important;
    height: 25px !important; */
    font-size: 0;
    box-shadow: none !important;
    /* border-radius: 0; */
    padding: 0 !important;
    margin: 4px 4px;
    min-width: inherit;
    position: relative;
    /* border: 1px solid #888; */
    width: 30px !important;
    height: 28px !important;
    border-radius: 4px;
    /* background: #fff; */
    background: url('https://all-frontend-assets.s3.amazonaws.com/practice2lab/selecting_hand_icon.png') center center;
    background-size: 30px;
}

.mat-mdc-dialog-container .chosse_btn button,
.mat-mdc-dialog-container .chosse_btn button .mat-mdc-button-touch-target,
.mat-mdc-dialog-container .chosse_btn button .mat-mdc-focus-indicator,
.mat-mdc-dialog-container .chosse_btn button .mat-mdc-button-persistent-ripple.mdc-button__ripple,
.mat-mdc-dialog-container .chosse_btn button .mat-ripple.mat-mdc-button-ripple {
    background: none !important;
    font-size: 0 !important;
    padding: 0 !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 16px !important;
    top: 0px;
    left: 0px;
    margin: 0px !important;
}

.mat-mdc-dialog-container .chosse_btn button .mat-mdc-button-touch-target {
    top: 12px !important;
}

.mat-mdc-dialog-container .selectedRow .chosse_btn button::before {
    /* content: '';
    position: absolute;
    top: 8px;
    left: 3px;
    background: #fff;
    width: 5px;
    height: 2px;
    transform: rotate(47deg);
    border-radius: 50px; */
}

.mat-mdc-dialog-container .selectedRow .chosse_btn button::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 5px;
    background: #fff;
    width: 10px;
    height: 2px;
    transform: rotate(138deg);
}

.fileUploadModal.expandFileUploadModal {
    width: 80% !important;
    height: 80vh;
}

.fileUploadModal.expandFileUploadModal .mat-mdc-dialog-surface {
    height: 100% !important;
}

.fileUploadModal.expandFileUploadModal .mat-mdc-dialog-surface .pdfFileSection {
    height: 59vh;
}

.fileUploadModal.expandFileUploadModal .mat-mdc-dialog-surface .modal-button-wrapper {
    margin-top: 3px;
}


/* <------------------CSS By Sanket------------------> */

@media only screen and (max-width:1280px) {
    .adminlisttable_v1 .multipledeleteandupdatebuttan {
        margin-left: 600px;
    }
}

@media only screen and (max-width:1199px) {
    .admin-list_v1 .lib-pager-class {
        margin-top: 0;
    }
    .admin-list_v1 .CustomButtonListen_div {
        padding-left: 0 !important;
    }
    .adminlisttable_v1 .multipledeleteandupdatebuttan {
        margin-left: 0;
        margin-top: 0;
        top: 0;
        position: relative;
        width: fit-content;
        margin: 0 0 0 auto;
        margin-bottom: -60px
    }
    .doctorChosseModal.cdk-overlay-pane .chooseDoctorModalContainer {
        /* width: 400px !important; */
        width: 100%;
    }
}

@media only screen and (max-width:991px) {
    .adminlisttable_v1 .multipledeleteandupdatebuttan {
        margin: 0 auto;
    }
    .admin-list_v1 .lib-pager-class {
        margin-top: 0;
        width: fit-content;
        margin: 0 auto;
        background: transparent
    }
    .admin-list_v1 .CustomButtonListen_div {
        position: inherit;
        bottom: inherit;
        flex-wrap: wrap
    }
    .adminlisttable_v1 .togglesearchcls .CustomButtonListen_div .add_button {
        margin: 4px 0;
        width: calc(100% - 8px);
        display: block
    }
    .admin-list_v1 .togglesearchcls {
        padding-bottom: 0px;
    }
    .admin-list_v1 .container {
        padding: 0 0
    }
    .admin-list_v1 .listingtableheading_wrapper {
        margin: 0 0px 10px 0px;
    }
    .progressbar_wrapper {
        margin: 0;
    }
    .adminlisttable_v1 thead {
        display: none;
    }
    .adminlisttable_v1 tr {
        display: block;
    }
    .adminlisttable_v1 tbody td:nth-child(2):before {
        content: 'NO.';
        color: #f58720!important;
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
    }
    .adminlisttable_v1 tbody td:last-child:before {
        content: 'action';
        color: #f58720!important;
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
    }
    .adminlisttable_v1 td:before {
        content: attr(title);
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        width: 50%!important;
        padding-bottom: 0;
        color: #f58720
    }
    .adminlisttable_v1 .multipledeleteandupdatebuttan {
        position: inherit;
        z-index: 9;
        text-align: center;
        display: block;
        width: auto;
        padding: 25px 0 0 0
    }
    .adminlisttable_v1 .mat-mdc-table .mdc-data-table__row {
        height: auto;
        margin-bottom: 10px
    }
    .adminlisttable_v1 tbody tr td {
        border: 1px solid #ccc!important;
        flex: 1 0 25%;
        font-weight: 400;
        display: flex;
        overflow: inherit;
        align-items: center;
        padding: 10px!important;
    }
    .adminlisttable_v1 tbody tr td span {
        word-break: break-all;
    }
    .admin-list_v1 .mat-mdc-table {
        border: 0 solid #9baebb;
        background: transparent
    }
    .admin-list_v1 tbody tr:nth-child(odd) {
        background-color: #fff !important;
    }
    .admin-list_v1 tbody tr:nth-child(even) {
        background-color: #ebf7ff !important
    }
    .admin-list_v1 .lib-pager-class {
        flex-wrap: wrap;
        justify-content: center
    }
    .admin-list_v1 .lib-pager-class mat-label {
        width: 100%;
        text-align: center;
        margin-bottom: 10px
    }
    .admin-list_v1 .mat-toolbar-row .mat-mdc-form-field {
        flex: 1 0 99%;
        margin: 0;
        margin: .5%
    }
    .admin-list_v1 .searchbtncls .search_class {
        margin-top: 10px;
    }
    .diagnostic_listwrapper .tablewrapper tbody tr td:nth-child(5) {
        word-break: unset;
        width: auto;
    }
}

.doctorSwitchButton {
    position: fixed;
    top: 22vh;
    right: 0px;
    width: 40px;
    height: 40px;
    border: 2px solid #fe9900;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 28px;
    color: #005891;
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}


/* =========================== List Style1 Css End ==============================*/

.notebtncls {
    display: inline-block!important;
    margin: 0;
    padding: 0!important;
    font-size: 0;
    min-width: inherit!important;
    background: url(https://all-frontend-assets.s3.amazonaws.com/practice2lab/notes_icon.png) no-repeat center center!important;
    background-size: 100%!important;
    width: 18px;
    box-shadow: none!important
}

.custom-modalbox .mat-mdc-subheader {
    font-size: 22px!important;
    text-align: center;
    color: #fff!important;
    margin: 0 auto!important;
    padding: 10px!important;
    line-height: 24px!important;
    background: #294b6d;
    display: block;
    height: auto!important
}

.custom-modalbox .mat-mdc-dialog-content {
    margin: 0;
    padding: 0;
}

.custom-modalbox .mdc-list {
    margin: 0;
    padding: 0;
}

.custom-modalbox .mdc-list-item {
    overflow: inherit;
    margin: 0;
    padding: 0;
}

.custom-modalbox .mdc-list-item.mdc-list-item--with-one-line {
    height: auto;
    min-height: inherit;
    border-bottom: solid 1px #ccc;
    position: relative
}

.custom-modalbox .mdc-list-item.mdc-list-item--with-one-line:last-of-type {
    border: none;
}


/* .custom-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-surface{padding: 0!important;} */

.custom-modalbox .mdc-list-item__content {
    text-align: left;
    padding: 5px 10px;
}

.custom-modalbox .mat-line:first-of-type {
    color: #01426c;
    font-size: 18px!important;
}

.custom-modalbox .mdc-list-item__primary-text .material-icons:first-of-type {
    display: none;
}

.custom-modalbox .mdc-list-item__primary-text .line-user {
    color: #333;
    font-size: 14px!important;
    padding-right: 40px;
}

.custom-modalbox .mdc-list-item__primary-text .line-user span {
    color: #02a2cb;
}

.custom-modalbox .mdc-list-item__primary-text .line-datetime {
    color: #746868;
    font-size: 12px!important;
    padding-right: 40px;
}

.custom-modalbox .mdc-list-item.mdc-list-item--with-one-line .material-icons:last-of-type {
    position: absolute;
    right: 8px;
    bottom: 4px;
    background: #294b6d;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 100%;
}

.custom-modalbox .textareanotewrapper {
    background-color: #ccc;
    padding: 10px;
}

.custom-modalbox .textareanotewrapper textarea {
    width: 100%;
    background: #fff;
    padding: 10px;
    font-family: inherit;
    font-size: 14px;
    resize: none;
    border: none;
    outline: none;
}

.delete-notes-modal .mdc-card {
    border: none;
    box-shadow: none;
}

.delete-notes-modal .mat-mdc-card-content {
    padding: 0!important;
}

.delete-notes-modal h3 {
    color: #02a2cb;
    font-size: 22px!important;
    margin: 0 0 8px 0;
}

.delete-notes-modal p {
    color: #111;
    font-size: 18px!important;
}

.delete-notes-modal .deletenotescls {
    margin: 12px 0 0 0;
}

.delete-notes-modal button {
    margin: 0 10px 0 0;
}

.headinggrp1 {
    width: 100%;
}

.mdc-list-item__primary-text {
    white-space: normal!important;
}

.mat-mdc-card {
    border-radius: 0;
    box-shadow: none;
}

.title_wrapper {
    background: #0e689d;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
}

.title_wrapper h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
}

.title_wrapper button {
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    background: #fc9826;
    color: #fff;
    border-radius: 8px;
    text-shadow: 1px 1px 1px #a35407;
    cursor: pointer;
}

.link_title {
    background: #1fc5e2;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    margin-bottom: 5px;
}

.link_title h3,
.link_title h3 a {
    margin: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
}

.iconwrapper a {
    height: 40px;
    width: 40px;
    background: #16699b2e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    box-shadow: 2px 3px 3px #00000069;
    border: 1px solid #4bdef7;
    text-decoration: none;
}

.iconwrapper a i {
    height: 30px;
    width: 30px;
    background: #16699b96;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
}

.iconwrapper {
    display: flex;
    align-items: center;
}

.iconwrapper a:active {
    box-shadow: none;
    transform: translateY(4px);
}

.UniqueName-modal {
    min-width: 450px;
}

.UniqueName-modal .mat-mdc-dialog-surface .close_button {
    right: 0;
    top: 0;
}

.UniqueName-modal .mat-mdc-dialog-container .mdc-button {
    display: block;
    margin: 0 auto;
}

.unique_form .errortitle {
    color: #fc4c24;
    text-align: center;
}

.as_logowrapper {
    width: 300px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 10px;
}

.as_logowrapper img {
    max-width: 100%;
    display: block;
}


/* -------------------------------------------------------------------------------------------------------------------    */


/* -------------------------------------------------------------------------------------------------------------------    */


/* -------------------------------------------------------------------------------------------------------------------    */


/* -------------------------------------------------------------------------------------------------------------------    */

@media only screen and (max-width:1199px) {
    .admin-list_v1 .lib-pager-class {
        flex-wrap: wrap;
        justify-content: center;
    }
    .adminlisttable_v1 .multipledeleteandupdatebuttan {
        position: inherit;
        z-index: 9;
        text-align: center;
        display: block;
        width: auto;
        padding: 25px 0 0 0;
        margin: 0;
    }
    .admin-list_v1 .togglesearchcls {
        padding-bottom: 20px;
    }
    .adminlisttable_v2 .example-section {
        width: 100%;
    }
    .adminlisttable_v2 .tablewrapper {
        float: none;
        width: 100%;
        padding-top: 15px;
    }
    .adminlisttable_v2 .togglesearchcls {
        float: none;
        width: 100%;
    }
    .listingtableheading {
        width: 100%;
        position: inherit;
        margin-bottom: 15px;
    }
    .listingtableheading_wrapper {
        padding: 10px 15px;
        text-align: center;
        border-radius: 5px;
        justify-content: center;
    }
    .libListNoDataCSS .listingtableheading_wrapper h2 {
        display: inline-block;
        padding-right: 10px;
        width: auto
    }
    .libListNoDataCSS .listingtableheading_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .listingtableheading_wrapper h2 {
        display: block;
        width: 100%;
    }
    .listingtableheading_wrapper h2 label {
        display: block;
        text-align: left;
        padding: 0;
    }
    .adminlisttable_v2 .lib-pager-class:first-of-type {
        color: #02609a;
        margin-top: 30px;
        padding: 10px;
        border-radius: 4px;
        background: #eee;
        width: auto;
        top: 0;
        right: 0;
        position: inherit;
        float: none;
    }
    .adminlisttable_v2 thead {
        display: none;
    }
    .adminlisttable_v2 tr {
        display: block;
    }
    .adminlisttable_v2 tbody td:nth-child(2):before {
        content: 'NO.';
        color: #23aae1!important;
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
    }
    .adminlisttable_v2 tbody td:last-child:before {
        content: 'action';
        color: #23aae1!important;
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
    }
    .adminlisttable_v2 td:before {
        content: attr(title);
        float: none;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        width: 50%!important;
        padding-bottom: 0;
        color: #23aae1
    }
    .adminlisttable_v2 tbody tr td {
        padding: 10px!important;
    }
    .adminlisttable_v2 .multipledeleteandupdatebuttan {
        position: inherit;
        z-index: 9;
        text-align: center;
        display: block;
        width: auto;
        padding: 25px 0 0 0
    }
}

@media only screen and (max-width:768px) {
    .detail-view .example-card .mat-mdc-card-header {
        width: 100%;
    }
    .detail-view .example-card .mat-mdc-card-content,
    .mat-mdc-card-subtitle {
        width: 100%;
    }
}

@media only screen and (max-width:640px) {
    .libListNoDataCSS .listingtableheading_wrapper {
        display: block;
        text-align: center;
    }
    .libListNoDataCSS .listingtableheading_wrapper h2 {
        display: block;
        text-align: center;
        padding: 0 0 10px 0;
    }
    .listingtableheading_wrapper h2 {
        display: block;
        text-align: center;
    }
    .listingtableheading_wrapper h2 label {
        text-align: center;
    }
}

@media only screen and (max-width:599px) {
    .link_title {
        flex-direction: column;
    }
    .link_title h3,
    .link_title h3 a {
        word-break: break-all;
    }
}

@media only screen and (max-width:399px) {
    .adminlisttable_v2 .actionbuttonwrapper,
    .adminlisttable_v1 .actionbuttonwrapper {
        margin-top: 10px;
    }
    .adminlisttable_v2 td:before,
    .adminlisttable_v1 td:before {
        width: 100%!important;
    }
    .adminlisttable_v2 tbody tr td,
    .adminlisttable_v1 tbody tr td {
        display: block;
    }
}

@media only screen and (max-width:469px) {
    .admin-list_v1 .lib-pager-class .pageformfield {
        margin-bottom: 10px;
    }
    .admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field {
        width: 98%;
    }
}

.diagnosticcategorycss .form_field_wrapperdiagnostic_category_des {
    flex: 1 0 99%;
}

.diagnosticcategorycss .form_field_wrapperdiagnostic_category_des .mat-mdc-form-field-flex {
    height: auto!important;
    padding-bottom: 10px;
}

.diagnosticcategorycss .form_field_wrapperdiagnostic_category_des .mat-mdc-form-field-flex textarea {
    height: 180px!important;
}

.admin-list_v1.searchcol4 .mat-toolbar-row .mat-mdc-form-field {
    flex: 1 0 23.33%;
}

.add_btn {
    background: rgb(40, 66, 97);
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    padding: 8px 20px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 5px;
    margin-left: 5px;
    box-shadow: 4px 4px 4px #974d0a;
}

.add_btn:active {
    box-shadow: none;
    transform: translateY(4px);
}

.previewButton button,
.previewButton button:hover {
    position: relative;
    background: none;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0;
    min-width: inherit;
    padding: 0 5px
}

.previewButton button::after {
    content: "\f06e";
    font: normal normal normal 14px/1 FontAwesome;
    color: #0f3955;
    font-size: 21px
}

.resend button::after {
    content: "\f045";
    font: normal normal normal 14px/1 FontAwesome;
    color: #0f3955;
    font-size: 21px
}

.resend button,
.resend button:hover {
    position: relative;
    background: none;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0;
    min-width: inherit;
    padding: 0 5px
}

.righticon button::after {
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    color: #19c14b;
    font-size: 21px
}

.righticon button,
.righticon button:hover {
    position: relative;
    background: none;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0;
    min-width: inherit;
    padding: 0 5px
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #e5701e !important;
}

.markasdoneButton button,
.markasdoneButton button:hover {
    position: relative;
    background: none;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0;
    min-width: inherit;
    padding: 0 5px
}

.markasdoneButton button::after {
    content: "\f093";
    font: normal normal normal 14px/1 FontAwesome;
    color: #0f3955;
    font-size: 21px
}

.reversestatusButton button,
.reversestatusButton button:hover {
    position: relative;
    background: none;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0;
    min-width: inherit;
    padding: 0 5px
}

.reversestatusButton button::after {
    content: "\f122";
    font: normal normal normal 14px/1 FontAwesome;
    color: #0f3955;
    font-size: 21px
}

.approveButton button,
.approveButton button:hover {
    position: relative;
    background: none !important;
    background-color: transparent!important;
    box-shadow: none !important;
    font-size: 0px !important;
    min-width: inherit;
    padding: 0 5px !important;
}

.approveButton button::after {
    content: "\f204";
    font: normal normal normal 14px/1 FontAwesome;
    color: #0f3955;
    font-size: 21px
}

.fileUploadDiv .form_field_wrapperclinical_report {
    border: 1px solid #ccc;
    width: 100%;
    min-height: 160px;
    border-radius: 4px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.fileUploadDiv .form_field_wrapperclinical_report #dropclinical_report {
    height: auto;
    width: auto;
    padding: 10px 20px;
    margin: 10px 5px 16px;
    cursor: pointer !important;
    text-align: center;
    background: #025d98;
}

.fileUploadDiv .submitbtnsection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileUploadDiv .submitbtnsection button {
    margin: 0px 3px;
}

.fileUploadDiv .fileUploadLibForm .form_field_wrapperclinical_report .filesid {
    max-height: 41vh;
    width: 100%;
    overflow-y: scroll;
    padding-left: 20px;
    /* border-top: 1px solid #ccc; */
}

.fileUploadDiv .fileUploadLibForm .form_field_wrapperclinical_report .form_field_clinical_report {
    width: 100%;
}

.assay_listwrapper .actionbuttonwrapperspan span:nth-child(2),
.diagnostic_listwrapper .actionbuttonwrapperspan span:nth-child(2),
.hidepreviewbtn .actionbuttonwrapperspan span:nth-child(2) {
    display: none;
}

.insurance_listwrapper.diagnostic_listwrapper .actionbuttonwrapperspan span:nth-child(2) {
    display: block;
}

.addEditPageWrapper .form_field_wrapper.form_field_wrapperprimary_isurance_id .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
.addEditPageWrapper .form_field_wrapper.form_field_wrappersecondary_isurance_id .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    height: auto !important;
    min-height: 50px !important;
}

.form_field_wrapperprimary_isurance_no.hightAdjust .mat-mdc-form-field-flex,
.form_field_wrappersecondary_isurance_no.hightAdjust .mat-mdc-form-field-flex {
    height: 76px !important;
}

.form_field_wrapperprimary_isurance_no.hightAdjust input,
.form_field_wrappersecondary_isurance_no.hightAdjust input {
    line-height: 56px;
}

.intake_formwrapper .form-group {
    width: 100%;
}

.intake_formwrapper {
    margin: 15px 0;
    overflow: hidden;
}

.intake_formwrapper .grp1 {
    display: flex;
    flex-wrap: wrap;
}

.intake_formwrapper .grp1 .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.intake_formwrapper .grp3 {
    display: flex;
    flex-wrap: wrap;
}

.intake_formwrapper .grp3 .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.intake_formwrapper .grp0 .form_field_wrapper,
.intake_formwrapper .grp2 .form_field_wrapper,
.intake_formwrapper .grp4 .form_field_wrapper,
.intake_formwrapper .grp6 .form_field_wrapper,
.intake_formwrapper .grp8 .form_field_wrapper,
.intake_formwrapper .grp10 .form_field_wrapper {
    margin: 0;
}

.patientInfoFormHeading,
.intake_formwrapper .grp0 .mat-mdc-card,
.intake_formwrapper .grp2 .mat-mdc-card,
.intake_formwrapper .grp4 .mat-mdc-card,
.intake_formwrapper .grp6 .mat-mdc-card,
.intake_formwrapper .grp8 .mat-mdc-card,
.intake_formwrapper .grp10 .mat-mdc-card,
.intake_formwrapper .heading .mat-mdc-card {
    background: #99d6e5;
    padding: 15px 22px;
    font-size: 20px;
    color: #000;
    border-radius: 0;
    margin-bottom: 2px;
    border: 1px solid #6eafbf;
    font-weight: 500;
    text-shadow: 1px 1px 1px #b4e9f5;
}

.patientInfoFormHeading mat-card {
    background: none;
}

.intake_formwrapper .heading .mat-mdc-card {
    background: #fc9826;
    color: #fff;
    text-shadow: none;
    border: none;
}

.intake_formwrapper .heading .form_field_wrapper {
    margin: 5px 0px;
}

.intake_formwrapper .grp1,
.intake_formwrapper .grp3,
.intake_formwrapper .grp5,
.intake_formwrapper .grp7,
.intake_formwrapper .grp0body,
.intake_formwrapper .grp2body,
.intake_formwrapper .grp4body,
.intake_formwrapper .grp6body,
.intake_formwrapper .grp8body,
.intake_formwrapper .grp10body {
    padding: 15px 15px;
    border: 1px solid #c4c6c7;
    margin-bottom: 10px;
    background: #e7e7e7;
}

.intake_formwrapper .grp7 {
    display: flex;
    flex-wrap: wrap;
}

.intake_formwrapper .grp0body .mat-mdc-form-field,
.intake_formwrapper .grp2body .mat-mdc-form-field,
.intake_formwrapper .grp4body .mat-mdc-form-field,
.intake_formwrapper .grp6body .mat-mdc-form-field,
.intake_formwrapper .grp8body .mat-mdc-form-field {
    margin: 5px 0;
}

.intake_formwrapper .grp0body .form-element,
.intake_formwrapper .grp2body .form-element,
.intake_formwrapper .grp4body .form-element,
.intake_formwrapper .grp6body .form-element,
.intake_formwrapper .grp8body .form-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.intake_formwrapper .grp0body .form-element mat-label,
.intake_formwrapper .grp2body .form-element mat-label,
.intake_formwrapper .grp4body .form-element mat-label,
.intake_formwrapper .grp6body .form-element mat-label,
.intake_formwrapper .grp8body .form-element mat-label {
    font-size: 16px;
}

.intake_formwrapper .grp0body .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp2body .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp4body .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp6body .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp8body .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp10 .form_field_wrapper .mat-mdc-form-field-flex {
    height: auto !important;
    min-height: 50px !important;
}

.intake_formwrapper .grp0body .form_field_wrapper .mat-mdc-form-field-flex textarea,
.intake_formwrapper .grp2body .form_field_wrapper .mat-mdc-form-field-flex textarea,
.intake_formwrapper .grp4body .form_field_wrapper .mat-mdc-form-field-flex textarea,
.intake_formwrapper .grp6body .form_field_wrapper .mat-mdc-form-field-flex textarea,
.intake_formwrapper .grp8body .form_field_wrapper .mat-mdc-form-field-flex textarea,
.intake_formwrapper .grp10 .form_field_wrapper .mat-mdc-form-field-flex textarea {
    height: 50px;
}

.intake_formwrapper .grp10 .form_field_wrapper .mat-mdc-form-field-flex,
.intake_formwrapper .grp10 .form_field_wrapper .mat-mdc-form-field-flex textarea {
    min-height: 100px;
}

.intake_formwrapper .grp0body .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-error,
.intake_formwrapper .grp2body .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-error,
.intake_formwrapper .grp4body .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-error,
.intake_formwrapper .grp6body .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-error,
.intake_formwrapper .grp8body .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-error {
    bottom: -22px;
}

.intake_formwrapper .grp0body .form_field_wrapper .notes {
    background: #fff;
    padding: 5px;
    border: 1px solid #d0d0d0;
    height: 80px;
    overflow-y: scroll;
}

.intake_formwrapper .grp10 .mat-mdc-form-field {
    margin: 0;
}

.intake_formwrapper .grp7 .form_field_wrapper {
    width: 130px;
    flex: inherit;
}

.intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+1) {
    width: calc(49% - 355px);
    padding: 8px 10px;
    margin-right: 0;
    display: flex;
    align-items: center;
    background: #e1d1ba;
    border: 1px solid #b3a087;
    border-right: 0;
}

.intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+2) {
    margin-left: 0;
    margin-right: 0;
    padding: 8px 10px;
    background: #e1d1ba;
    border-top: 1px solid #b3a087;
    border-bottom: 1px solid #b3a087;
}

.intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+3) {
    margin-right: 0;
    margin-left: 0;
    background: #e1d1ba;
    border-top: 1px solid #b3a087;
    border-bottom: 1px solid #b3a087;
    padding: 8px 0px;
}

.intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+4) {
    padding: 8px 0px;
    width: 100px;
    margin-left: 0;
    background: #e1d1ba;
    border: 1px solid #b3a087;
    border-left: 0;
}

.addEditPageWrapper.intake_formwrapper .form_field_wrapper .mdc-floating-label--float-above {
    display: none;
    top: 7px !important;
}


/* mdc-floating-label mat-mdc-floating-label ng-tns-c115-8 ng-star-inserted
mdc-floating-label mat-mdc-floating-label ng-tns-c115-8 ng-star-inserted mdc-floating-label--float-above */

.intake_formwrapper .grp7 .form_field_wrapper .mat-mdc-card {
    background-color: transparent;
    font-size: 16px;
}

.physician_intake_formwrapper.intake_formwrapper input[readonly="true"] {
    color: #666 !important;
}

.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #fe9900 !important;
    background-color: #fe9900 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #fe9900 !important;
    background-color: #fe9900 !important;
}

.intake_formwrapper .physicianExaminationForm .form {
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; */
}

.intake_formwrapper .physicianExaminationForm .form-group.heading {
    /* width: 100%; */
}

.intake_formwrapper .questionnaireContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.intake_formwrapper .questionnaireContainer .questionnaireInnerDiv,
.intake_formwrapper .physicianExaminationForm .innerDiv {
    flex: 0 1 calc( 50% - 5px);
}

.intake_formwrapper .questionnaireContainer .questionnaireInnerDiv:nth-child(odd),
.intake_formwrapper .physicianExaminationForm .innerDiv:nth-child(odd) {
    margin-right: 5px;
}

.intake_formwrapper .questionnaireContainer .questionnaireInnerDiv:nth-child(even),
.intake_formwrapper .physicianExaminationForm .innerDiv:nth-child(even) {
    margin-left: 5px;
}

.intake_formwrapper .innerDiv .grp10 .mat-mdc-form-field-flex {
    border-radius: 0px;
}

.intake_formwrapper .questionnaireContainer .grp0body .form_field_wrapper .additionalNotes .notes,
.intake_formwrapper .innerDiv .grp10 .form_field_wrapper .mat-mdc-form-field-flex textarea {
    min-height: 149px;
}

.intake_formwrapper .innerDiv .grp10 .form_field_wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding-bottom: 12px;
}

.intake_formwrapper .innerDiv .mat-mdc-form-field-subscript-wrapper {
    height: 3px;
}

.grp7.disbledAllCheckbox mat-checkbox,
.grp7.disbledAllCheckbox .symptomNoteEditButton {
    pointer-events: none
}

.intake_formwrapper .leadIntakeFormDiv .grp7 {
    padding: 4px 0px 1% 0px;
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv {
    flex: 0 1 32%;
    margin-top: 1%;
    margin-left: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e1d1ba;
    border: 1px solid #b3a087;
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv .form_field_wrapper {
    width: auto;
    flex: 0 1 10%;
    margin: 0px;
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv .form_field_wrapper:first-child {
    flex: 0 1 90%;
}

.intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv {
    flex: 0 1 49%;
}

.intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv .form_field_wrapper:first-child {
    flex: 0 1 60%;
    padding-left: 15px;
    padding-right: 15px;
}

.intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv .form_field_wrapper {
    flex: 0 1 16%;
    padding: 8px 0px;
}

.intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv .form_field_wrapper:nth-child(4n+4) {
    flex: 0 1 13% !important;
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv .symptomNoteEditButton {
    background: none;
    border: none;
    color: #0e689d;
    margin-right: 4px;
    margin-left: -10px;
    cursor: pointer;
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv:nth-child(even) {
    /* margin-left: .5%; */
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv:nth-child(odd) {
    /* margin-right: .5%; */
}

.intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv .form_field_wrapper {
    background: none;
    border: none;
}

@media only screen and (max-width: 1700px) {
    .intake_formwrapper .grp7 .form_field_wrapper .mat-mdc-card {
        font-size: 14px;
    }
    .intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv .form_field_wrapper:first-child {
        flex: 0 1 56%;
    }
    .intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv .form_field_wrapper:nth-child(4n+4) {
        flex: 0 1 16% !important;
    }
}

@media only screen and (max-width: 1420px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+1) {
        width: calc(48.5% - 355px);
    }
}

@media only screen and (max-width: 1280px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+1) {
        width: calc(98% - 355px);
    }
    .thankyoupage_subwrapper {
        position: relative;
        z-index: 5555;
    }
    .thankyoupage_wrapper2 {
        background: #fff;
    }
}

@media only screen and (max-width: 1199px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+1) {
        width: calc(98% - 355px) !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+2) {
        width: 130px !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+3) {
        width: 130px !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+4) {
        width: 100px !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper {
        margin: 3px 0!important
    }
    .intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv {
        flex: 0 1 47%;
        margin-left: 2%;
        margin-bottom: 1%;
    }
    .intake_formwrapper .leadIntakeFormDiv.otherIntakeFormDiv .grp7 .innerDiv {
        flex: 0 96%;
    }
    .addEditPageWrapper .form_field_wrapper.form_field_wrappersecondary_isurance_id,
    .addEditPageWrapper .form_field_wrapper.form_field_wrapperprimary_isurance_id {
        flex: 0 1 49%;
        margin-right: 1% !important;
    }
    .addEditPageWrapper .leadIntakeFormDiv .form_field_wrapper {
        margin-right: 1% !important;
    }
}

@media only screen and (max-width: 767px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+1) {
        width: calc(100%) !important;
        border: 1px solid #b3a087;
        margin-bottom: 0 !important;
        border-bottom: 0;
        background: #b3884b;
        color: #fff;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+4) {
        width: 33.33% !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+3) {
        width: 33.33% !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+2) {
        width: 33.33% !important;
        border-left: 1px solid #b3a087;
        padding-right: 0;
    }
    .intake_formwrapper .grp7 .form_field_wrapper {
        margin: 7px 0!important;
        margin-top: 0 !important;
    }
    .intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv {
        flex: 0 1 100%;
        margin: 7.5px 15px;
    }
    .intake_formwrapper .leadIntakeFormDiv .grp7 .innerDiv .form_field_wrapper {
        color: #000;
    }
    .addEditPageWrapper .form_field_wrapper.form_field_wrappersecondary_isurance_id,
    .addEditPageWrapper .form_field_wrapper.form_field_wrapperprimary_isurance_id,
    .addEditPageWrapper .leadIntakeFormDiv .form_field_wrapper {
        flex: 0 1 100%;
        margin-right: 0% !important;
    }
    .addEditPageWrapper .leadIntakeFormDiv .form_field_wrapper.form_field_wrapperdiagnosed mat-label,
    .addEditPageWrapper .leadIntakeFormDiv .form_field_wrapper.form_field_wrapperdiabetes mat-label,
    .addEditPageWrapper .leadIntakeFormDiv .form_field_wrapper.form_field_wrappercovid19 mat-label {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
    }
    .symptoms-modal-class {
        min-width: auto !important;
    }
}

@media screen and (max-width: 600px) {
    .labDiagnosisModal .labDiagnosis.admin-list_v1 .lib-pager-class .pageformfield .mat-mdc-form-field {
        width: 100%;
        margin: 0px auto !important;
        margin-bottom: 5px !important;
    }
    .api-data .example-card .mat-mdc-card-header .mat-mdc-card-title,
    .api-data .mat-mdc-dialog-surface .mat-mdc-dialog-content p.innerhtml-content {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+4) {
        width: 26.33% !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+3) {
        width: 36.33% !important;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+2) {
        width: 37.33% !important;
    }
    .listingtableheading_wrapper .add_btn:first-child {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .listingpage_wrapper .location_col_wrapper {
        width: 100%;
        display: block;
    }
    .listingpage_wrapper .location_col_wrapper .location_col .location_iconwrapper {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 430px) {
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+4) {
        width: 100% !important;
        border: 1px solid #b3a087;
        border-top: 0;
        padding: 0px 15px;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+3) {
        width: 100% !important;
        margin-top: 0 !important;
        border: 1px solid #b3a087;
        border-top: 0;
        border-bottom: 0;
        margin-bottom: 0 !important;
        padding: 0px 15px;
    }
    .intake_formwrapper .grp7 .form_field_wrapper:nth-child(4n+2) {
        width: 100% !important;
        border: 1px solid #b3a087;
        border-bottom: 0;
        margin-bottom: 0 !important;
        padding: 0px 15px;
    }
}

@media screen and (max-width:380px) {
    .use_summary_col7 .adminlisttable_v2 .lib-pager-class:first-of-type {
        justify-content: center;
        margin-top: 8px;
    }
    .api-data .mat-mdc-dialog-surface .dialoghead .mat-mdc-dialog-content {
        padding: 20px 0px !important;
    }
    .api-data {
        max-width: 100vw !important;
    }
}

.submitbtnsectionPreview {
    display: none!important;
}


/* lib sign div  */

@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
.libsigndiv {
    position: relative;
    padding-bottom: 65px;
}

.libsigndiv .submitbtnsection {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
}

.sign_FormInput .mat-mdc-form-field-subscript-wrapper {
    display: none!important;
}

.addEditPageWrapper .sign_FormInput .mat-mdc-form-field-flex {
    background: #f3f3f3;
    border: none;
    outline: none;
    font-family: 'Caveat';
    color: #000!important;
    font-size: 30px;
}

.sign_FormInput input {
    color: #000!important;
}

.addEditPageWrapper .sign_FormInpu .mdc-text-field--disabled .mdc-text-field__input {
    color: #000;
}

.addEditPageWrapper .sign_FormInput .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
    background: #f3f3f3;
    padding: 0;
    top: 15px!important;
    border-radius: 0;
    border: none;
    outline: none;
}

.addEditPageWrapper .sign_FormInput .mat-mdc-form-field {
    position: relative;
    margin-bottom: 0;
    width: 100%;
}

.addEditPageWrapper .signBTN {
    background: linear-gradient(0deg, rgba(12, 98, 153, 1) 0%, rgba(13, 161, 255, 1) 100%);
    line-height: 25px;
    padding: 12px 15px!important;
    box-shadow: none!important;
}

.signPageDialog {
    width: 600px;
    max-width: 90%;
}

.signPageDialogCon {
    height: 30vh;
    margin: 0 -20px;
    padding: 0 20px;
    padding-bottom: 10px;
    max-height: 40Vh;
    min-height: auto;
    overflow: scroll;
    overflow-x: hidden;
}

.termsNcond .box h3 {
    color: #249ac7;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
}

.termsNcond h4 {
    color: #224f60;
    margin: 0;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
}

.termsNcond a {
    text-decoration: none;
    color: #fc9826;
}

.signPageDialogConForm h5 {
    background: #1a2e46;
    margin: 0 -20px;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.signPageDialog .signFormat {
    background: #d0d0d0;
    border-radius: 5px;
    font-family: 'Caveat';
    font-size: 22px;
    height: 46px;
    margin: 10px auto;
    overflow: hidden;
    padding: 10px;
    width: 100%;
    color: #000!important;
}

.labReportViewModal .mat-mdc-dialog-surface {
    border: none;
    padding: 0px !important;
}

.labReportViewModalBody {
    padding: 20px;
    width: 30vw;
    border: solid 10px #1a2e46;
    position: relative;
    overflow: hidden;
}

.labReportViewModalBody .reportList {
    padding: 0px 40px;
}

.labReportViewModalBody ul li {
    padding: 4px 6px;
    font-size: 20px;
}

.labReportViewModalBody .close_button {
    border: none;
    top: 0px;
    right: 0px;
}


/* .cdk-global-scrollblock{ overflow-y: inherit!important; top: 0!important;} */

.signPageDialogConFormDiv button {
    margin: 2px 5px;
}

.signPageDialogBody .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-form-field-bottom-align {
    display: block;
}

.signPageDialogBody .mat-mdc-form-field-error {
    display: block!important;
}

.signPageDialogBody .mat-mdc-dialog-container .errortext {
    display: block!important;
    width: 100%;
    position: inherit;
    color: #ff0000;
    font-size: 12px;
    bottom: 0!important;
    margin-top: -10px;
}

.signPageDialogBody .mat-mdc-form-field-bottom-align::before {
    display: none;
}

.sign_FormInput .addEditPageWrapper form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
}

.sign_FormInput .mat-mdc-form-field-error {
    display: block;
    width: 100%;
    padding: 0 10px;
}

.signclass {
    padding: 0 10px 12px 0;
}

.signclass label {
    display: block;
    color: #107a9b!important;
    font-size: 12px;
    margin: 0 0 10px 0;
    font-weight: 500;
}

.signclass span {
    display: block;
    color: #000!important;
    font-family: 'Caveat';
    font-size: 30px;
}

.patientIintakeSuccess {
    text-align: center;
}

.patientIintakeSuccess h1 {
    margin: 0;
    padding: 10px 0;
    font-size: 24px;
    color: #000;
}

.patientIintakeSuccess .mdc-button {
    height: auto!important;
    font-size: 18px!important;
    box-shadow: none;
    margin: 5px!important;
    display: inline-block;
}

.chooseDoctorModalContainer {
    width: 320px;
}

.chooseDoctorModalContainer mat-chip-list mat-chip {
    max-width: 94%;
}

.chooseDoctorModalContainer mat-chip-list mat-chip.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.chooseDoctorModalContainer mat-chip-list mat-chip.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.chooseDoctorModalContainer mat-chip-list mat-chip.mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden;
}

.resetformcss .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6)!important;
}

.resetformcss .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
    color: rgba(0, 0, 0, 0.6)!important;
}

.approve_modal_mainwrapper .closealert {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px;
    color: #fff !important;
    border-radius: 0 0 0 10px;
    background: #1a2e46;
    padding: 0 0 2px 2px;
    font-size: 20px;
    z-index: 9;
    width: 24px;
    height: 24px;
    min-width: auto;
}

.approve_modal_mainwrapper .closealert mat-icon {
    height: auto;
    width: auto;
    font-size: 24px;
    margin-right: 0px;
}

@media only screen and (max-width: 991px) {
    .addEditPageWrapper .signBTN {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .addEditPageWrapper .sign_FormInput .mat-mdc-form-field-flex input {
        text-align: center;
    }
    .addEditPageWrapper .sign_FormInput .mat-mdc-form-field-infix {
        width: auto;
    }
    .addEditPageWrapper .sign_FormInput .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        width: 100%;
        text-align: center;
    }
    .addEditPageWrapper .sign_FormInput .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        top: -8px!important;
        font-size: 12px;
        height: 20px;
    }
    .addEditPageWrapper .sign_FormInput .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        margin-top: 6px;
    }
    .sign_FormInput .mat-mdc-form-field-error {
        text-align: center;
    }
}


/* lib sign div END */

.slideBtn {
    position: absolute;
    top: 50%;
}

.nextSlide.slideBtn {
    right: 20px;
}

.isPDFFile {
    transition: 0.3s ease-in-out;
    /* position: absolute;
    left: 0px;
    top: 0px; */
    width: 100%;
}

.isPDFFile.displayBlock {
    display: block;
    opacity: 1;
    /* left: 0px; */
}

.isPDFFile.displayNone {
    display: none;
    opacity: 0;
    /* left: 100%; */
}

.clinicalReportAvailable img {
    width: 100%;
}

.clinicalReportAvailable {
    min-height: 60vh;
}

.clinicalReportAvailable .isPDFFile {
    min-height: 80vh;
}

.clinicalReportAvailable embed {
    width: 65%;
    margin: 0px auto;
    display: block;
    min-height: 80vh;
}

.leadPreviewDiv {
    position: relative;
}

.btn_download_pdf {
    background: #fc9826;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    position: fixed;
    top: 195px;
    right: 20px;
    width: 35px;
    height: 35px;
    z-index: 666;
    transition: .2s ease-in-out 0s;
}

.btn_download_pdf:hover {
    width: 42px;
    height: 42px;
    transform: scale(1);
    /* top: 190px; */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
}

.button_titlebar .mat-mdc-tab-header {
    width: 93%;
    display: flex;
    margin: 0 auto;
    background-color: #ddd;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
}

.button_titlebar .mat-mdc-tab-group {
    min-height: 82vh;
}

.button_titlebar .fullTab .mat-mdc-tab.mdc-tab {
    flex: 1 0 100%;
}

.button_titlebar .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    /* flex: 1 0 50%; */
    flex: auto !important;
    width: 100%;
}

.button_titlebar.reportAvailable .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    flex: 1 0 33%;
}

.button_titlebar .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label {
    color: #fff !important;
}

.button_titlebar .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
    background: #fc9826;
    color: #fff;
}

.mat-mdc-tab-body-wrapper .intake_formwrapper {
    margin-top: 0 !important;
}

.button_titlebar .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.button_titlebar .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #a5570b;
}

.button_titlebar .mat-mdc-tab-labels {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.copyurl_bodal_container p {
    padding: 20px;
    background: #fde5c0;
    font-size: 19px;
    margin-bottom: 0;
}

.copyurl_bodal_container a {
    background: rgb(1, 187, 221);
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    padding: 10px 22px;
    font-size: 18px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    color: #fff;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 23px;
}

.copyurl_bodal_container .ng-star-inserted {
    display: flex;
    padding: 20px 0;
}

.symptoms-modal-class {
    min-width: 600px;
    overflow-y: auto;
}

.symptoms-modal-class .mat-mdc-chip {
    position: relative;
    margin: 2px;
}

.allReasonsDiv {
    max-height: 40vh;
    overflow-y: scroll;
}


/* width */

.symptoms-modal-class::-webkit-scrollbar {
    width: 0px;
}

.approve_button_wrapper {
    display: flex;
    justify-content: center;
}

.approve_button_wrapper button {
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto!important;
    font-size: 22px;
    line-height: 50px;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    box-shadow: none;
    padding: 0 16px !important;
    margin: 5px !important;
}

.approve_modal_mainwrapper p {
    font-size: 18px;
    background: #f5bf7c;
    padding: 15px 20px;
}

.pdf_block {
    /* margin-left: -9999px; */
    /* visibility: hidden; */
    /* overflow: hidden; */
    position: fixed;
    left: -9999px;
}

.reject-notes-modal textarea {
    height: 130px;
    line-height: 16px;
    resize: none;
}

.reject-notes-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-form-field .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .symptomReasonModal .mat-mdc-form-field .mat-mdc-form-field-flex {
    height: 150px!important;
}

.reject-notes-modal textarea,
.symptomReasonModal textarea {
    height: 125px !important;
    resize: none;
}

.reject-notes-modal {
    min-width: 600px !important;
}

.reject-notes-modal .mat-mdc-dialog-container .mdc-button {
    display: block;
    margin: 0 auto;
}

.reject-notes-modal .mat-mdc-dialog-container .error {
    position: inherit;
    bottom: inherit;
    margin-top: -10px;
}

.symptomReasonModal p {
    font-size: 16px;
    text-align: center;
}

.modal-button-wrapper {
    display: flex;
    justify-content: center;
}

.mat-mdc-dialog-container .modal-button-wrapper .mdc-button {
    margin: 5px;
}

.confirm-modal p,
.mat-mdc-dialog-content p {
    font-size: 20px;
    color: #141414;
}

.mat-mdc-dialog-content p {
    margin-top: 10px !important;
}

.mdc-dialog__actions {
    padding-bottom: 0 !important;
}

.mat-mdc-dialog-container .dialoghead h1 {
    border-radius: 5px;
    margin: 0 auto !important;
    padding: 15px !important;
    display: block;
    background: linear-gradient(180deg, rgba(1, 187, 221, 1) 0%, rgba(2, 93, 152, 1) 100%);
    font-size: 26px !important;
    color: #fff !important;
    margin-bottom: 15px;
    text-align: center !important;
    position: relative;
}

.approve_modal_mainwrapper.practice_upgrade_modal .closealert {
    top: -10px;
}

.approve_modal_mainwrapper.practice_upgrade_modal h3 {
    font-size: 20px;
    color: #141414;
    margin: 16px auto;
    text-align: center;
}

.approve_modal_mainwrapper.practice_upgrade_modal h3 button {
    border: none;
    background: none;
    color: #fc9826;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    padding: 0px;
    outline: none;
}

.approve_modal_mainwrapper.practice_upgrade_modal .bodyContent .btnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.approve_modal_mainwrapper.practice_upgrade_modal .bodyContent .btnDiv button {
    margin: 10px 5px;
    background: linear-gradient(180deg, rgba(40, 66, 97, 1) 0%, rgba(26, 46, 70, 1) 100%);
    height: auto;
    font-size: 22px;
    font-weight: 600;
    line-height: 50px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    box-shadow: none;
    padding: 0 16px;
    text-transform: uppercase;
    outline: none;
}


/* .mat-mdc-dialog-container .dialoghead h1::before{
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
        display: inline;
} */

.signSubmitContainer {
    overflow-y: scroll;
    max-height: 60vh;
}

.mat-mdc-dialog-container .mat-mdc-dialog-surface .signSubmitContainer .additionalNotesDiv .mat-mdc-form-field .mat-mdc-form-field-flex {
    height: auto !important;
}

.new-container {
    padding: 0 4rem;
    display: block;
    margin: 0 auto
}

.main-title {
    padding: 2rem 0;
    box-shadow: none!important;
    background: 0 0!important;
    text-align: center
}

.main-title h2 {
    margin: 0!important;
    font-size: 2.4rem!important;
    text-transform: uppercase;
}

@media only screen and (max-width:1199px) {
    .new-container {
        padding: 0 2rem
    }
}

.rapidResponseVirtualAccessModal .admin-list_v1 .togglesearchcls .mat-toolbar-row {
    padding: 10px!important;
}

.rapidResponseVirtualAccessModal .pageformfield {
    display: flex!important;
    margin: 0 5px!important;
}

.VirtualPlatformAccess .multipledeleteandupdatebuttan .mat-raised-button {
    display: none;
}

.mat-mdc-dialog-container .adminlisttable_v1 .multipledeleteandupdatebuttan {
    margin-left: 0;
    margin-top: 0;
    top: 0;
    position: relative;
    text-align: center;
}

.mat-mdc-dialog-container .adminlisttable_v1 .multipledeleteandupdatebuttan button {
    margin-bottom: 2px;
}

.mat-mdc-dialog-container .admin-list_v1 .lib-pager-class {
    margin-top: 0;
}

.mat-mdc-dialog-container .admin-list_v1 .togglesearchcls {
    padding-bottom: 10px;
}

@media only screen and (max-width:1400px) {
    .mat-mdc-dialog-container .admin-list_v1 .lib-pager-class {
        display: block!important;
        text-align: center;
    }
    .mat-mdc-dialog-container .pageformfield {
        display: flex!important;
        margin: 0 auto!important;
        width: 450px!important;
    }
}

@media only screen and (max-width:991px) {
    .mat-mdc-dialog-container .pageformfield {
        display: block!important;
        margin: 0 auto!important;
        width: 168px!important;
    }
    .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
        padding: 0!important;
    }
    .rapidResponseVirtualAccessModal {
        width: 80vw;
    }
    .mat-mdc-dialog-container .admin-list_v1 .lib-pager-class {
        padding-left: 0;
        padding-right: 0;
    }
}

.VirtualPlatformAccess .multipledeleteandupdatebuttan .mat-mdc-raised-button {
    display: none;
}

.approveBtn {
    display: none;
}

.approveBtnaction {
    display: block!important;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
}

.PinionModal .mat-mdc-dialog-surface .close_button {
    right: 0;
    top: 0;
}

.PinionModal .professional_opinionmain {
    max-height: 60vh;
    overflow-y: auto;
    padding: 10px;
}

.PinionModal .professional_opinion h1 {
    text-align: center;
    margin: 0;
    padding: 15px;
    background: #ceeaff;
    font-size: 20px;
    border: 1px solid #8ccdfd;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 10px;
}

.PinionModal .diagnosis-item {
    background: #f5fbff;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #dadfe3;
    box-shadow: 2px 3px 6px #d0cfcf85;
    padding: 15px;
}

.PinionModal .diagnosis-item:last-of-type {
    margin: 0;
}

.PinionModal .diagnosis-item h4 {
    font-size: 21px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    padding-bottom: 12px;
    display: block;
    position: relative;
}

.professional_opinionmain h1 {
    text-align: center;
    margin: 0;
    padding: 15px;
    background: #ceeaff;
    font-size: 24px;
    border: 1px solid #8ccdfd;
    border-radius: 5px;
}

.PinionModal .diagnosis-item h4::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(18, 129, 212);
    background: linear-gradient(90deg, rgba(18, 129, 212, 1) 0%, rgba(18, 129, 212, 0) 100%);
}

.PinionModal .diagnosis-item ul {
    padding-left: 15px;
}

.PinionModal .diagnosis-item ul li {
    font-size: 16px;
    background: transparent;
    padding: 8px 6px;
    padding-left: 35px;
    position: relative;
    margin: 0;
    list-style: none;
}

.PinionModal .diagnosis-item ul li::before {
    content: '';
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    top: 8px;
    border-radius: 50%;
    background-color: #ffa319;
    border: 4px solid #fff;
    box-shadow: 2px 3px 4px #b5b3b3, inset 2px 2px 4px #9d6511;
}

.addEditPageWrapper  .form_field_range_max_value_notes .mat-mdc-form-field-flex {
    min-height: 100px!important;  max-height: 100px!important;
}

.addEditPageWrapper  .form_field_range_min_value_notes .mat-mdc-form-field-flex {
    min-height: 100px!important;  max-height: 100px!important;
}

